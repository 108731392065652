<template>
  <div>
    <PageLoader v-bind:storage="appLoading" />
    <v-dialog
      v-model="cropImageDialog"
      persistent
      :width="
        $vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm'
          ? '100vw'
          : $vuetify.breakpoint.name == 'md'
          ? '80vw'
          : $vuetify.breakpoint.name == 'lg'
          ? '50vw'
          : '40vw'
      "
    >
      <v-card>
        <v-layout wrap justify-center pa-2>
          <v-flex xs12>
            <ImageCropper
              :image="currentImage"
              :key="currentImage"
              @stepper="imageCropper"
            />
          </v-flex>
        </v-layout>
      </v-card>
    </v-dialog>
    <v-layout pt-4 wrap justify-left>
      <v-flex xs12 xl11 px-2>
        <v-layout pt-3 wrap justify-start>
          <v-flex xs5 sm3 md3 lg2 xl2>
            <v-layout wrap justify-center>
              <v-flex xs3 sm2 text-left align-self-center>
                <v-img
                  src="./../../../assets/greenlogo.png"
                  contain
                  height="15px"
                  alt="STYLOOP"
                ></v-img>
              </v-flex>
              <v-flex xs9 sm8 text-left pl-2 align-self-center>
                <span
                  style="
                    font-family: poppinssemibold;
                    font-size: 18px;
                    color: #1c1c1c;
                  "
                >
                  Gallery
                </span>
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex
            xs12
            sm9
            md9
            lg10
            xl10
            text-center
            text-sm-right
            align-self-center
          >
            <v-dialog
              :retain-focus="false"
              v-model="dialog"
              persistent
              max-width="600px"
              :key="dialog"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  width="250px"
                  tile
                  dark
                  color="#30B868"
                  depressed
                  v-bind="attrs"
                  v-on="on"
                  class="text-capitalize"
                  :ripple="false"
                >
                  Add Gallery
                </v-btn>
              </template>
              <v-flex xs12 md12 lg12 xl12 pl-sm-4 pt-2 pb-4>
                <v-layout wrap justify-center>
                  <v-flex pt-4 xs12 text-center>
                    <v-layout wrap justify-center>
                      <v-flex xs8 sm8 md12 lg12>
                        <v-card flat tile outlined height="500px" width="570px">
                          <v-layout wrap justify-center fill-height>
                            <v-flex md12 lg12 align-self-center>
                              <v-img
                                v-if="imageArray[0]"
                                :src="imageArray[0]"
                                height="298px"
                                contain
                              >
                                <v-layout wrap>
                                  <v-flex text-right pa-2>
                                    <v-avatar color="#FF3434" size="20">
                                      <v-icon
                                        color="#FFF"
                                        small
                                        @click="removeImageArray(0)"
                                      >
                                        mdi-close
                                      </v-icon>
                                    </v-avatar>
                                  </v-flex>
                                </v-layout>
                              </v-img>
                            </v-flex>
                          </v-layout>
                        </v-card>
                      </v-flex>
                      <v-flex xs4 sm3 md3 lg4>
                        <v-layout pt-0 wrap justify-center>
                          <v-flex
                            xs12
                            v-for="(item, i) in imageArray"
                            :key="i"
                            pa-1
                          >
                            <v-img
                              v-if="i > 0"
                              :src="item"
                              height="85px"
                              contain
                            >
                              <v-layout wrap>
                                <v-flex text-right pa-0>
                                  <v-avatar color="#FF3434" size="15">
                                    <v-icon
                                      color="#FFF"
                                      small
                                      @click="removeImageArray(i)"
                                    >
                                      mdi-close
                                    </v-icon>
                                  </v-avatar>
                                </v-flex>
                              </v-layout>
                            </v-img>
                          </v-flex>
                        </v-layout>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                  <v-flex xs12 sm9 md9 lg12 pt-4 text-left>
                    <v-btn
                      width="180px"
                      small
                      tile
                      depressed
                      :ripple="false"
                      class="text-capitalize"
                      color="#EFEFEF"
                      @click="$refs.files.click()"
                    >
                      <v-layout wrap justify-center>
                        <v-flex xs8 text-right>
                          <span
                            style="
                              font-size: 12px;
                              font-family: opensanssemibold;
                            "
                          >
                            Upload Image
                          </span>
                        </v-flex>
                        <v-flex xs4 text-right>
                          <v-img
                            src="./../../../assets/icons/file-upload.svg"
                            height="15px"
                            contain
                          ></v-img>
                        </v-flex>
                      </v-layout>
                    </v-btn>
                    <input
                      v-show="false"
                      accept="image/*"
                      id="file"
                      ref="files"
                      multiple="multiple"
                      type="file"
                      @change="uploadImages"
                    />
                  </v-flex>
                </v-layout>
                <v-layout wrap justify-end pt-5>
                  <v-flex xs12 sm6 ms6>
                    <v-btn
                      width="250px"
                      tile
                      color="#30B868"
                      depressed
                      dark
                      @click="dialog = false"
                    >
                      close
                    </v-btn>
                  </v-flex>
                  <v-flex xs12 sm6 md6>
                    <v-btn
                      width="250px"
                      tile
                      color="#32d92b"
                      depressed
                      dark
                      @click="uploadAllImages()"
                    >
                      Add
                    </v-btn>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-dialog>
          </v-flex>
        </v-layout>
        <v-layout wrap pt-5 justify-start v-if="gallery.length > 0">
          <template v-for="(item, i) in gallery">
            <v-flex xs12 sm6 md3 lg3 text-right :key="i" pr-lg-6 pr-xl-0>
              <v-img :src="mediaURL + item">
                <v-badge
                  offset-x="20"
                  offset-y="2"
                  small
                  overlap
                  avatar
                  color="#30B868"
                >
                  <template v-slot:badge>
                    <v-dialog v-model="item.dialogDelete" max-width="600px">
                      <template v-slot:activator="{ on, attrs }">
                        <v-avatar>
                          <v-icon small v-on="on" v-bind="attrs"
                            >mdi-close</v-icon
                          >
                        </v-avatar>
                      </template>
                      <v-card>
                        <v-card-title
                          style="
                            font-family: poppinsregular;
                            font-size: 15px;
                            color: #000;
                          "
                          >Are you sure you want to delete this
                          Image?</v-card-title
                        >
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn
                            color="blue darken-1"
                            text
                            small
                            @click="item.dialogDelete = false"
                            >Cancel</v-btn
                          >
                          <v-btn
                            color="blue darken-1"
                            text
                            small
                            @click="remove(i)"
                            >OK</v-btn
                          >
                          <v-spacer></v-spacer>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                  </template>
                  <template v-slot:placeholder>
                    <ImageLoader />
                  </template>
                </v-badge>
              </v-img>
            </v-flex>
          </template>
        </v-layout>

        <v-layout wrap justify-center v-if="gallery.length < 1">
          <v-flex xs12>
            <span
              style="
                font-family: poppinsregular;
                font-size: 25px;
                color: #000000;
              "
            >
              Oops! No Images Found
            </span>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";
import ImageCropper from "./../../Common/imageCropper";

export default {
  components: {
    ImageCropper,
  },
  data() {
    return {
      appLoading: false,
      ServerError: false,
      currentPage: 1,
      pages: 1,
      count: 10,
      imageArray: [],
      Images: new FormData(),
      formData: new FormData(),
      selectedFiles: null,
      image: null,
      cropImageDialog: false,
      currentImage: null,
      dialog: false,
      dialogDelete: false,
      productTotalCount: 0,
      gallery: [],
      activeCount: 0,
      inactiveCount: 0,
      totalCount: 0,
      productData: {},
      listStatus: "All",
      searchKey: null,
    };
  },
  computed: {
    appUser() {
      return this.$store.state.userData;
    },
  },
  beforeMount() {
    this.getData();
  },
  methods: {
    imageCropper(payload) {
      this.cropImageDialog = payload.dialog;
      if (payload.image) {
        this.collectImages(payload.image);
      }
    },
    getData() {
      this.appLoading = true;
      axios({
        method: "GET",
        url: "/seller/info",
        headers: {
          token: localStorage.getItem("token"),
        },
        params: {
          id: this.appUser._id,
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.gallery = response.data.data.photos;
          } else {
            this.gallery = [];
          }
        })
        .catch((err) => {
          this.ServerError = true;
          console.log(err);
        });
    },
    uploadImages(event) {
      if (this.imageArray.length < 4) {
        this.currentImage = URL.createObjectURL(event.target.files[0]);
        this.cropImageDialog = true;
      } else {
        this.msg = "Maximum image limit exceeded!";
        this.showSnackBar = true;
        return;
      }
    },
    collectImages(image) {
      this.selectedFiles = image;
      this.Images.append("image", this.selectedFiles);
      this.formData.append("photos", this.selectedFiles);
      var img = new Image();
      img.src = window.URL.createObjectURL(image);
      img.onload = () => {
        const urls = URL.createObjectURL(image);
        this.imageArray.push(urls);
        if (this.imageArray) {
          this.image = this.imageArray[0];
        }
      };
    },
    removeImageArray(i) {
      var values = this.formData.getAll("photos");
      values.splice(i, 1);
      this.formData.set("photos", values);
      this.imageArray.splice(i, 1);
    },
    uploadAllImages() {
      this.appLoading = true;
      axios({
        method: "POST",
        url: "/seller/UploadImageMultiple",
        data: this.formData,
        headers: {
          "Content-Type": "multipart/form-data",
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.msg = "Uploaded Images";
            this.showsnackbar = true;
            this.dialog = false;
            setTimeout(() => {
              this.getData();
            }, 1000);
          } else {
            this.msg = "Can't Upload Images";
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    remove(r) {
      var data = {};
      data["position"] = r + 1;
      axios({
        url: "/seller/RemoveImagesingle",
        method: "POST",
        data: data,
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.delete = false;
          this.appLoading = false;
          if (response.data.status) {
            this.msg = "Delete Sucessfully";
            this.showsnackbar = true;
            this.dialogDelete = false;
            this.getData();
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
  },
};
</script>
